<template>
  <div class="content-copy">
    <div
      v-if="description"
      class="copy sm:text-base mb-8"
      v-html="$md.render(description)"
    ></div>
    <div class="relative w-full aspect-[16/9]">
      <iframe v-not-lazy
        :id="compId"
        class="border-none absolute top-0 left-0 w-full h-full"
        type="text/html"
        :src="videoUrl"
        allow="fullscreen"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import getVideoId from 'get-video-id'

const uuid = useId()

interface VideoProps {
  src: string
  description?: string
}

const props = defineProps<VideoProps>()

const videoId = computed(() => {
  const { id } = getVideoId(props.src)
  return id
})

const videoService = computed(() => {
  const { service } = getVideoId(props.src)
  return service
})

const compId = computed(() => {
  const prefix = videoService.value === 'vimeo' ? 'vimeoplayer' : 'ytplayer'
  return `${prefix}-${uuid}`
})

const videoUrl = computed(() => {
  if (videoService.value === 'youtube') {
    return `https://www.youtube.com/embed/${videoId.value}`
  } else if (videoService.value === 'vimeo') {
    return `https://player.vimeo.com/video/${videoId.value}`
  }
  // ToDo: ask for custom design for invalid video URL
  return `https://www.youtube.com/embed/${videoId.value}`
})
</script>
